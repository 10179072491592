export default {
  getAdminContractList: {
    url: "/documents",
    method: "get"
  },
  getUserContractList: {
    url: "/documents/users",
    method: "get"
  },
  postDocuments: {
    url: "/documents",
    method: "post"
  },
  postDocumentsDocumentIdParticipators:{
    url: "/documents/:documentId/participators",
    method: "post",
    placeholder: [":documentId"]
  },
  postDocumentsDocumentIdAttachments:{
    url: "/documents/:documentId/attachments",
    method: "post",
    placeholder: [":documentId"]
  },
  getDocumentEditRecords:{
    url:"/document_edit_records",
    method: "get"
  },
  getDocumentsId:{
    url:"/documents/:id",
    method: "get",
    placeholder: [":id"]
  },
  deleteContract: {
    url: "/documents?ids=:ids",
    method: "delete",
    placeholder: [":ids"]
  },
  getUserCategories: {
    url: "/categories/departments",
    methods: "get"
  },
  getCategoies: {
    url: "/categories",
    method: "get"
  },
  deleDocumentsDocumentIdParticipators:{
    url: "/documents/:documentId/participators",
    method: "delete",
    placeholder: [":documentId"]
  },
  deleDocumentsDocumentIdParticipators:{
    url: "/documents/:documentId/participators",
    method: "delete",
    placeholder: [":documentId"]
  },
  deleDocumentsDocumentIdAttachments:{
    url: "/documents/:documentId/attachments?ids=:ids",
    method: "delete",
    placeholder: [":documentId",":ids"]
  },
  putDocumentsId:{
    url:"/documents/:id",
    method: "put",
    placeholder: [":id"]
  },
  postDocumentsSerialNumber:{
    url:"/documents/serial_number",
    method: "post",
  }
};
