export default {
  getAccountList: {
    url: "/users",
    method: "get"
  },
  saveAccountInfo: {
    url: "/users",
    method: "post"
  },
  getAccountDetail: {
    url: "/users/:id",
    method: "get",
    placeholder: [":id"]
  },
  updateAccountInfo: {
    url: "/users/:id",
    method: "put",
    placeholder: [":id"]
  },
  delAccountInfo: {
    url: "/users/:id",
    method: "delete",
    placeholder: [":id"]
  },
  getClientList: {
    url: "/ext/keycloak/kc-clients",
    method: "get"
  },
  putUserSuperior: {
    url: "/users/:uid/superior?spid=:spid",
    method: "put",
    placeholder: [":uid", ":spid"]
  },
  getRoles: {
    url: "/roles",
    method: "get"
  },
  saveRoleInfo: {
    url: "/roles",
    method: "post"
  },
  postUserRoles: {
    url: "/users/:uid/role-mappings/clients/:cid",
    method: "post",
    placeholder: [":uid", ":cid"]
  },
  removeUserRoles: {
    url: "/users/:uid/role-mappings/clients/:cid",
    method: "delete",
    placeholder: [":uid", ":cid"]
  },
  removeRole: {
    url: "/roles/:id",
    method: "delete",
    placeholder: [":id"]
  },
  getUserOrdinates: {
    url: "/users/:uid/subordinates",
    method: "get",
    placeholder: [":uid"]
  },
  getUserRoleList: {
    url: "/users/:userId/role-mappings/clients/:clientId",
    method: "get",
    placeholder: [":userId", ":clientId"]
  },
  // 组织
  getOrganizationList: {
    url: "/orgs",
    method: "get"
  },
  getOrganizationInfoById: {
    url: "/orgs/:orgId",
    method: "get",
    placeholder: [":orgId"]
  },
  setOrganization: {
    url: "/orgs/:orgId",
    method: "put",
    placeholder: [":orgId"]
  },
  postOrganization: {
    url: "/orgs",
    method: "post"
  },
  deleteOrganization: {
    url: "/orgs/:orgId",
    method: "delete",
    placeholder: [":orgId"]
  },
  // 部门
  getDepartmentList: {
    url: "/dept",
    method: "get"
  },
  setDepartment: {
    url: "/dept/:deptId",
    method: "put",
    placeholder: [":deptId"]
  },
  postDepartment: {
    url: "/dept",
    method: "post"
  },
  deleteDepartment: {
    url: "/dept/:deptId",
    method: "delete",
    placeholder: [":deptId"]
  },

  // 岗位
  getPositionList: {
    url: "/positions/search/by-department",
    method: "get"
  },
  getPositionInfo: {
    url: "/positions/:positionId",
    method: "get",
    placeholder: [":positionId"]
  },
  setPosition: {
    url: "/positions/:positionId",
    method: "put",
    placeholder: [":positionId"]
  },
  deletePosition: {
    url: "/positions/:positionId",
    method: "delete",
    placeholder: [":positionId"]
  },
  postPosition: {
    url: "/positions",
    method: "post",
    placeholder: []
  },
  getAdminList: {
    url: "/users/search/by-client-role",
    method: "get"
  }
};
