export default {
  app: {
    applicationName: "",
    id: "17"
  },
  hotel: {
    hotelId: "bb2c6294-f92e-439a-b639-be724f4c43a9",
    hotelName: "皇后大酒店",
    hotelAddress: "皇后大道"
  },
  userInfo: {
    userId: "",
    userName: "qlc",
    preferred_username: "",
    phoneNumber: "",
    organizations: [], // 组织
    departments: [], // 部门
    flattenDepartments: [], // flatten department

    positions: [], // 岗位
    flattenPositions: [], // flatten岗位

    postId: "", // 岗位id
    postName: "", // 岗位name

    propertyName: "" // 物业名称
  },
  lastPerformTimeStamp: "",
  organization: [],
  uploadFileUrl: process.env.VUE_APP_CLASSROOM_HOST + "/appendixes/file"
};
