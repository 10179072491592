import Vue from "vue";
import Vuex from "vuex";
import house from "./house";
import root from "./root";
import form from "./form";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    house,
    form
  },
  ...root
});
