export default {
  getClassroomList: {
    url: "/classrooms",
    method: "get"
  },
  getClassroomBuilding: {
    url: "/classroom_buildings/names",
    method: "get"
  },
  addClassroom: {
    url: "/classrooms",
    method: "post"
  },
  deleteClassroom: {
    url: "/classrooms",
    method: "delete"
  },
  deleteClassroomAttchments: {
    url: "/classrooms/:id/attchments",
    method: "delete"
  },
  getClassroomDetail: {
    url: "/classrooms/:id",
    method: "get",
    placeholder: [":id"]
  },
  getClassroomAppointmentList: {
    url: "/classroom_appointments",
    method: "get"
  },
  getClassroomAppointmentsApplication: {
    url: "/classroom_appointments/application",
    method: "get"
  },
  getClassroomAppointmentListStaff: {
    url: "/classroom_appointments/staff",
    method: "get"
  },
  getClassroomAppointmentDetail: {
    url: "/classroom_appointments/:id",
    method: "get",
    placeholder: [":id"]
  },
  addClassroomAppointment: {
    url: "/classroom_appointments",
    method: "post"
  },

  // getClassroomDisableDate: {
  //   url: "/classrooms/:id/disable_dates",
  //   method: "get",
  //   placeholder: [":id"]
  // },
  getClassroomDate: {
    url: "/classrooms/:id/dates",
    method: "get",
    placeholder: [":id"]
  },
  getClassroomDateById: {
    url: "/classroom_appointments/:id",
    method: "get",
    placeholder: [":id"]
  },
  getClassroomDisabledDate: {
    url: "/classrooms/:id/disable_dates",
    method: "get",
    placeholder: [":id"]
  },
  postClassroomAppointments: {
    url: "/classroom_appointments",
    method: "post"
  },
  putClassroomAppointments: {
    url: "/classroom_appointments/:id",
    method: "put",
    placeholder: [":id"]
  },
  postClassroomAppointmentsChecks: {
    url: "/classroom_appointments/:appointmentId/checks",
    method: "post",
    placeholder: [":appointmentId"]
  },
  putClassroomAppointmentsStatus: {
    url: "/classroom_appointments/status",
    method: "put"
  },

  getClassroomBuildings: {
    url: "/classroom_buildings",
    method: "get"
  },

  getClassroomDisableDateTemplates: {
    url: "/classroom_disable_date_templates",
    method: "get"
  },
  putClassroomId: {
    url: "/classrooms/:id",
    method: "put",
    placeholder: [":id"]
  },
  deleteClassroomsIdFacilitys: {
    url: "/classrooms/:id/facilitys",
    method: "delete",
    placeholder: [":id"]
  },

  deleteClassroomsAttachments: {
    url: "/classrooms/:id/attachments?ids=:ids",
    method: "delete",
    placeholder: [":id", ":ids"]
  },

  postClassroomAppointmentsAppointmentIddatesTime: {
    url: "/classroom_appointments/:appointmentId/dates/time",
    method: "put",
    placeholder: [":appointmentId"]
  },
  putClassroomAppointmentsAppointmentIdDatesStatus: {
    url: "/classroom_appointments/:appointmentId/dates/status",
    method: "put",
    placeholder: [":appointmentId"]
  },
  getClassroomLockIds: {
    url: "/classrooms/locks",
    method: "get"
  },
  setClassroomLockStatus: {
    url: "/classroom_doorlocks/status",
    method: "put"
  },
  getClassroomInstallOrderList: {
    url: "/classroom_doorlock_install_orders",
    method: "get"
  },
  addClassroomInstallOrder: {
    url: "/classroom_doorlock_install_orders",
    method: "post"
  },
  setClassroomInstallOrderStatus: {
    url: "/classroom_doorlock_install_orders/status",
    method: "put"
  }
};
