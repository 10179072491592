import { TOKEN_TIME_EXPIRED } from "@/utils/config.js";
import { apis } from "@/apis/index.js";
import { SUCCESS_CODE } from "@/apis/notification.js";

export default {
  login({ commit }) {
    let data = {};
    commit("setUserInfo", data);
  },
  // 获取个人信息
  async getAccountDetail({ state }) {
    let ret = await apis.getAccountDetail({
      placeholder: { id: state.userInfo.userId }
    });
    if (ret.code != SUCCESS_CODE) return;

    // 删除里面的subgroups
    let organizations = JSON.parse(JSON.stringify(ret.data.groups));
    let departments = JSON.parse(JSON.stringify(ret.data.groups));
    let positions = JSON.parse(JSON.stringify(ret.data.groups));

    let flattenDepartments = [];
    departments.forEach(org => {
      org.subGroups.forEach(dep => {
        delete dep.subGroups;

        flattenDepartments = flattenDepartments.concat([dep]);
      });
    });

    let flattenPositions = [];
    positions.forEach(org => {
      org.subGroups.forEach(dep => {
        dep.subGroups.forEach(pos => {
          flattenPositions = flattenPositions.concat([pos]);
        });
      });
    });

    state.userInfo.organizations = organizations;
    state.userInfo.departments = departments;
    state.userInfo.flattenDepartments = flattenDepartments;

    state.userInfo.positions = positions;
    state.userInfo.flattenPositions = flattenPositions;

    state.userInfo.postId = ret.data.postId;
    state.userInfo.postName = ret.data.postName;
  },
  clearUserInfo({ commit }) {
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("expireAt", "");
    sessionStorage.setItem("isRefreshing", "");
    sessionStorage.setItem("grantedAuthority", "");
  },
  // 设置可靠时间
  setValidPerformanceTime({ state }, data) {
    state.lastPerformTimeStamp = data;
  },
  // 在可靠时间内，用户是否有操作
  hasValidPerformanceTime({ state }) {
    if (!state.lastPerformTimeStamp) return;
    let time = state.userInfo.expireAt - state.lastPerformTimeStamp;
    return TOKEN_TIME_EXPIRED > time && time > 3 * 1000;
  }
};
