// import { Loading } from "vant";
// import { Toast } from "vant";

const errorMsgMapping = {
  "STOCK_ITEM_INVENTORY.UNDERSTOCK": "库存不足",
  "ASSET.ALREADY_BORROWED": "资产已借出",
  "CLASSROOM.EXISTING_APPOINTMENT": "课室时段已存在预约",
  "STOCK_RACK.CODE_REPEATED": "货位编号已存在，请重新输入"
};

export let parseMsgRules = function(message) {
  let msgArr = (message && message.split(":")) || [];
  let key = msgArr[0];
  let msgContent = errorMsgMapping[key] || "數據返回出錯";
  let params = msgArr.slice(1);

  if (msgArr.length > 1) {
    // 長度大於1，有參數
    params = params.join(", ");
    return params + msgContent;
  } else {
    return msgContent;
  }
};

function showLoading() {
  // return Toast.loading({
  //   message: "加载中...",
  //   forbidClick: true,
  //   duration: 0,
  //   loadingType: "spinner"
  // });
  /* return Loading.service({
    fullscreen: true,
    lock: true,
    text: "正在加载中...",
    customClass: "global-loading",
    background: "transparent"
  }); */
}

// 房间预约状态
export const SUCCESS_CODE = "COMMON.SUCCESS";
export const CHECK_IN = "CHECK_IN";
export const AVAILABLE = "AVAILABLE";
export const RESERVED = "RESERVED";

// 房间状态
export const REPAIRING = "4";
export const CLEANING = "5";
export const DIRTY = "6";
export const LOCKED = "7";
export const VACANCY = "3";

export default {
  errorMsgMapping: errorMsgMapping,
  parseMsgRules: parseMsgRules,
  loading: showLoading
};
