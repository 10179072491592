import Vue from "vue";
let filters = {
  gender(val) {
    if (val === "male") return "男";
    if (val === "female") return "女";
    if (val === "1") return "男";
    if (val === "2") return "女";
    return "";
  },
  dateToAge(date) {
    return Vue.moment(date).diff(Vue.moment(), "years") + "年";
  },
  date(date, format = "YYYY-MM-DD HH:mm") {
    return Vue.moment(date).format(format);
  },
  summary(item) {
    let ret = "";
    let variables = item.variables;
    if (variables && variables.summaryTpl) {
      let text = variables.summaryTpl.replace("<%-taskName-%>", item.name);
      ret = text;
    }
    return ret;
  },
  dateTimeFormat(val, format = "YYYY-MM-DD") {
    return Vue.$moment(date).format(format);
  },

  numberToFixed(number) {
    return Number(number).toFixed(2);
  }
};

export default {
  install(Vue) {
    Object.keys(filters).forEach(key => {
      Vue.filter(key, filters[key]);
    });
  }
};
