export default {
  getWarehouseList: {
    url: "/warehouses",
    method: "get"
  },
  addWarehouse: {
    url: "/warehouses",
    method: "post"
  },
  setWarehouse: {
    url: "/warehouses/:id",
    method: "put",
    placeholder: [":id"]
  },
  getWarehouseDetail: {
    url: "/warehouses/:id",
    method: "get",
    placeholder: [":id"]
  },

  getWarehouseKeepers: {
    url: "/warehouses/:id/keepers",
    method: "get",
    placeholder: [":id"]
  },

  addWarehouseKeeper: {
    url: "/warehouses/:id/keepers",
    method: "post",
    placeholder: [":id"]
  },
  deleteWarehouseKeeper: {
    url: "/warehouses/:id/keepers",
    method: "delete",
    placeholder: [":id"]
  },

  getWarehouseOrgs: {
    url: "/warehouses/:id/organizations",
    method: "get",
    placeholder: [":id"]
  },

  addWarehouseOrg: {
    url: "/warehouses/:id/organizations",
    method: "post",
    placeholder: [":id"]
  },
  deleteWarehouseOrg: {
    url: "/warehouses/:id/organizations",
    method: "delete",
    placeholder: [":id"]
  },

  deleteWarehouse: {
    url: "/warehouses",
    method: "delete"
  },

  getStockItemType: {
    url: "/stock_item_categorys",
    method: "get"
  },
  addStockItemType: {
    url: "/stock_item_categorys",
    method: "post"
  },
  deleteStockItemType: {
    url: "/stock_item_categorys",
    method: "delete"
  },
  setStockItemType: {
    url: "/stock_item_categorys/:id",
    method: "put",
    placeholder: [":id"]
  },
  getStockItemTypeDetail: {
    url: "/stock_item_categorys/:id",
    method: "get",
    placeholder: [":id"]
  },

  getStockItemList: {
    url: "/stock_items",
    method: "get"
  },
  addStockItem: {
    url: "/stock_items",
    method: "post"
  },
  deleteStockItem: {
    url: "/stock_items",
    method: "delete"
  },
  getStockItemDetail: {
    url: "/stock_items/:id",
    method: "get",
    placeholder: [":id"]
  },
  setStockItem: {
    url: "/stock_items/:id",
    method: "put",
    placeholder: [":id"]
  },

  getStockWarehousingList: {
    url: "/stock_warehousings",
    method: "get"
  },
  getStockWarehousingDetail: {
    url: "/stock_warehousings/:id",
    method: "get",
    placeholder: [":id"]
  },
  handleRemoveAttachment: {
    url: "/stock_items/:stockItemId/attachments?ids=:ids",
    method: "delete",
    placeholder: [":stockItemId", ":ids"]
  },
  addStockItemAttachments: {
    url: "/stock_items/:stockItemId/attachments",
    method: "post",
    placeholder: [":stockItemId"]
  },
  saveStockItemAttachmentById: {
    url: "/stock_items/:stockItemId/attachments",
    method: "put",
    placeholder: [":stockItemId"]
  },
  addStockWarehouing: {
    url: "/stock_warehousings",
    method: "post"
  },
  setStockWarehousing: {
    url: "/stock_warehousings/:id",
    method: "put",
    placeholder: [":id"]
  },
  deleteStockWarehouings: {
    url: "/stock_warehousings",
    method: "delete"
  },
  deleteStockWarehouingItems: {
    url: "/stock_warehousings/:id/items",
    method: "delete",
    placeholder: [":id"]
  },
  addInventoryChecks: {
    url: "/stock_checks",
    method: "post"
  },
  getStockItemInventoryList: {
    url: "/stock_item_inventorys",
    method: "get"
  },
  getStockApplyInventoryList: {
    url: "/stock_claims_applys/inventory_items",
    method: "get"
  },
  getInventoryList: {
    url: "/stock_item_inventorys",
    method: "get"
  },
  deleteStockItemInventory: {
    url: "/stock_item_inventorys",
    method: "delete"
  },
  setStockItemInventoryStatus: {
    url: "/stock_item_inventorys/status",
    method: "put"
  },
  getStockItemInventoryDetail: {
    url: "/stock_item_inventorys/:id",
    method: "get",
    placeholder: [":id"]
  },
  getStockSiteList: {
    url: "/stock_item_inventorys/stock_sites",
    method: "get"
  },

  getStockDeliveryList: {
    url: "/stock_deliverys",
    method: "get"
  },
  addStockDelivery: {
    url: "/stock_deliverys",
    method: "post"
  },
  deleteStockDelivery: {
    url: "/stock_deliverys",
    method: "delete"
  },
  getStockDeliveryDetail: {
    url: "/stock_deliverys/:id",
    method: "get",
    placeholder: [":id"]
  },

  setStockDelivery: {
    url: "/stock_deliverys/:id",
    method: "put",
    placeholder: [":id"]
  },

  getClaimApplyList: {
    url: "/stock_claims_applys",
    method: "get"
  },
  getClaimApplyDetail: {
    url: "/stock_claims_applys/:id",
    method: "get",
    placeholder: [":id"]
  },
  getWarehousesList: {
    url: "/warehouses",
    method: "get"
  },
  // getStockItemBySearchName: {
  //   url: "/stock_claims_applys/stock_claims_applys",
  //   method: "get"
  // },
  getStockItemCategory: {
    url: "/stock_item_categorys",
    method: "get"
  },
  postNewStockClaimsApply: {
    url: "/stock_claims_applys",
    method: "post"
  },
  deleteStockClaimApplyById: {
    url: "/stock_claims_applys",
    method: "delete"
  },
  getWarehouseStockSiteList: {
    url: "/warehouses/:id/stock_racks",
    method: "get",
    placeholder: ["id"]
  },
  getWarehousesStockSiteDetail: {
    url: "/warehouses/:warehouseId/stock_racks/:id",
    method: "get",
    placeholder: [":warehouseId", ":id"]
  },
  setWarehouseStockSiteStatus: {
    url: "/warehouses/:id/stock_racks/status",
    method: "put",
    placeholder: [":id"]
  },
  addWarehouseStockSite: {
    url: "/warehouses/:id/stock_racks",
    method: "post",
    placeholder: [":id"]
  },
  setWarehouseStockSite: {
    url: "/warehouses/:warehouseId/stock_racks/:id",
    method: "put",
    placeholder: [":warehouseId", ":id"]
  },
  deleteWarehouseStockSite: {
    url: "/warehouses/:warehouseId/stock_racks?ids=:ids",
    method: "delete",
    placeholder: [":warehouseId", ":ids"]
  },

  addStockDeliveryItems: {
    url: "/stock_delivery_items",
    method: "post"
  },
  setStockDeliveryItemStatus: {
    url: "/stock_delivery_items/status",
    method: "put"
  },
  setStockDeliveryItems: {
    url: "/stock_delivery_items/:id",
    method: "put",
    placeholder: [":id"]
  },
  deleteStockDeliveryItems: {
    url: "/stock_delivery_items?ids=:ids",
    method: "delete",
    placeholder: [":ids"]
  },
  setStockDeliveryStatus: {
    url: "/stock_deliverys/status",
    method: "put"
  },
  setClaimsApplyPicking: {
    url: "/stock_claims_applys/:id/picking",
    method: "put",
    placeholder: [":id"]
  },
  getStockItemByBarCode: {
    url: "/stock_items/bar_code",
    method: "get"
  }
};
