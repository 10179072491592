export default {
  getLockList: {
    url: "/locks",
    method: "get"
  },
  getLockDetail: {
    url: "/locks/:lockId",
    method: "get",
    placeholder: [":lockId"]
  },
  deleteLock: {
    url: "/locks/:lockId?requestSource=:requestSource",
    method: "delete",
    placeholder: [":requestSource", ":lockId"]
  },
  getLogs: {
    url: "/logs",
    method: "get"
  },
  getLockKeygroups: {
    url: "/locks/:lockId/keygroups",
    method: "get",
    placeholder: [":lockId"]
  },
  addNBKeys: {
    url: "/nbCommands/addKey",
    method: "post"
  },
  deleteNBKeys: {
    url: "/locks/:lockId/keys/:keyId",
    placholder: [":lockId", ":keyId"],
    method: "delete"
  },
  getPasswordByLockId: {
    url: "/locks/:lockId/offline_pw?requestSource=NB",
    method: "post",
    placeholder: [":lockId"]
  }
};
