export default {
  getDictsList: {
    url: "/dicts",
    method: "get"
  },
  getAssetsOptionsById: {
    url: "/dicts/:id/options",
    method: "get",
    placeholder: [":id"]
  }
};
