export default {
  getAppendixFilePath: {
    url: "/appendixes",
    method: "get"
  },
  postAppendixFilePath: {
    url: "/appendixes",
    method: "post"
  },
  postAppendixBpmnFile: {
    url: "/appendixes/bpmn",
    method: "post"
  },
  deleteAppendixes: {
    url: "/appendixes?url=:url",
    method: "delete",
    placeholder: [":url"]
  }
};
