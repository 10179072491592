import axios from "axios";
import $utils from "utils/fn.js";
import notification from "apis/notification.js";
import Vue from "vue";
import classroom from "./services/classroom";
import property from "./services/property";
import dict from "./services/dict";
import organization from "./services/organization";
import assets from "./services/assets";
import bpmn from "./services/bpmn";
import workflow from "./services/workflow";
import workstation from "./services/workstation";
import wx from "./services/wx";
import lock from "./services/lock";
import appendix from "./services/appendix";
import assetsImport from "./services/assetsImport";
import contract from "./services/contract";

axios.defaults.timeout = 100000;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axios.defaults.baseURL = ""; //打包地址
axios.defaults.withCredentials = false;

// 赋值环境变量
window.process = { env: {} };
Object.assign(window.process.env, {
  ...process.env
});

let instance = null;
let iterator = 0;
let responseMsgs = [];
/**
 * hide loading
 */
function hideLoading() {
  if (iterator == 0 || iterator < 0) {
    iterator = 0;
    instance && instance.clear();
    instance = null;
    if (!axios.isMute) {
      handleMsgInResponse(responseMsgs);
    }
    axios.isMute = false;
  }
}

// 处理消息信息,201, 200, 400等等
function handleMsgInResponse(responses) {
  if (!responses.length) return;
  // 全部成功
  function allSuccess(responses) {
    return responses.every(item => {
      // 过滤bpmn的返回
      if (item.response && item.response.config.url.includes("/engine-rest")) {
        return item.status == 204 || item.status == 200;
      }

      return item.status === 0 || (item.code == "COMMON.SUCCESS" && item.status == 200);
    });
  }

  // 存在400、500
  function someError(responses) {
    return responses.filter(item => {
      return item.status >= 400;
    });
  }

  // 存在201
  function someWarning() {
    return responses
      .filter(item => {
        if (item.response && item.response.config.url.includes("/engine-rest")) {
          return item.status === 500;
        } else {
          return item.status === 200 && item.code !== "COMMON.SUCCESS";
        }
      })
      .map(item => item.code);
  }

  // start
  if (!allSuccess(responses)) {
    let errors = someError(responses);
    if (errors.length) {
      $utils.error("数据出错");
      responseMsgs = [];
      return;
    }

    let warnings = someWarning(responses);
    if (warnings.length) {
      warnings.forEach(item => {
        $utils.warning(notification.parseMsgRules(item));
      });
    }
  }

  responseMsgs = [];
}

// show loading
function showLoading() {
  instance = notification.loading();
}

// function isSlience() {}
axios.interceptors.request.use(
  async function (config) {
    if (location.href.indexOf("/login") != -1 || config.headers.isMute) {
      return config;
    }

    showLoading();
    let authInfo = await $utils.getKeyCloakToken();
    config.headers = config.headers || {};
    config.headers["Use-Type"] = "H5";

    config.headers.Authorization = "Bearer " + authInfo.token;
    config.headers["X-Realm"] = Vue.prototype.$realm;
    iterator++;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async function (response) {
    if (response.config.headers.isMute) {
      return response;
    }

    // 500ms 不会进来返回的拦截
    iterator--;
    responseMsgs.push({
      status: response.status,
      responseData: response.data,
      code: response.data.code || 'COMMON.SUCCESS',
      response
    });

    setTimeout(function () {
      hideLoading();
    }, 500);

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let handleResponseAjax = function (response) {
  // debugger
  // 特殊的url不需要检查code
  if (response.config.url.includes("/engine-rest") || response.config.url.includes("appendixes/bpmn")) {
    // success  status:200
    if (response.status >= 200 && response.status <= 300) return Promise.resolve({ code: "COMMON.SUCCESS", data: response.data });
    return Promise.reject({ code: "COMMON.FAIL", data: response });
  }
  // || response.data.status == 0
  if (response.status == 200 && response.data.code && response.data.code == "COMMON.SUCCESS") {
    // success  status:200
    return Promise.resolve(response.data);
  } else if (response.status == 200 && response.data.code && response.data.code != "COMMON.SUCCESS") {
    // success  status:201
    return Promise.resolve({ data: -1 });
  } else if (response.status == 403) {
    // success status:403
    $utils.jumpLogin();
    return Promise.reject(response);
  } else {
    // error {status:400, 500, data:{status:''}}
    return Promise.reject(response);
  }
};

function genApis(api) {
  return function (arg) {
    if (!arg) arg = {};
    let method = api.method,
      placeholder = api.url.match(/:[a-z_A-Z]+/g) || [],
      url = api.url,
      axiosParams = {};

    // placeholder
    if (placeholder.length) {
      for (let pl of placeholder) {
        url = url.replace(pl, arg.placeholder[pl.slice(1)]); // 占位符为 ":"
      }
    }

    // get delete , post put
    if (method == "get" || method == "delete") {
      axiosParams = { params: arg.params };
    } else if (method == "post" || method == "put") {
      axiosParams = { data: arg.params };
    }

    // 是否不loading提示，直接请求
    if (arg.headers) {
      axiosParams.headers = { ...arg.headers };
    }

    let resolvePromise = axios.request({ method, url, ...axiosParams }).then(res => {
      return handleResponseAjax(res);
    });

    // return promise data
    return resolvePromise
      .then(res => {
        return res;
      })
      .catch(e => {
        console.trace(e);

        iterator--;
        responseMsgs.push({ status: 500, code: "" });

        setTimeout(function () {
          hideLoading();
        }, 500);

        return false;
      });
  };
}

// 封装api, 返回一个函数
let apis = {};
[classroom, property, dict, organization, assets, bpmn, workflow, workstation, wx, lock, appendix, assetsImport, contract].forEach(item => {
  for (let key in item) {
    apis[key] = genApis($utils.pureCopy(item[key]));
  }
});

const config = {
  // interceptor
  transformRequest: [
    function (data) {
      // 对 data 进行任意转换处理
      data = data.replace(/%5B\d+%5D/g, "");
      return data;
    }
  ]
};

export { axios, apis, config };
