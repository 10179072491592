export default {
  getAssetsItemsBySearchName: {
    url: "/assets",
    method: "post"
  },
  getAssetsByQuery: {
    url: "/assets",
    method: "get"
  },
  postNewAssetsLending: {
    url: "/asset_lendings",
    method: "post"
  },
  setAssetsStatus: {
    url: "/assets/using_status",
    method: "put"
  },
  deleteAssetsLendingById: {
    url: "/asset_lendings",
    method: "delete"
  },
  //资产帐 API
  getAssets: {
    url: "/assets",
    method: "get"
  },
  getAssetsId: {
    url: "/assets/:id",
    method: "get",
    placeholder: [":id"]
  },
  postAssets: {
    url: "/assets",
    method: "post"
  },
  putAssetsStatus: {
    url: "/assets/status",
    method: "put"
  },
  putAssetsId: {
    url: "/assets/:id",
    method: "put",
    placeholder: [":id"]
  },
  deleteAssets: {
    url: "/assets",
    method: "delete"
  },
  removeAssetsAttachment: {
    url: "/assets/:id/attachments?attachmentIds=:attachmentIds",
    method: "delete",
    placeholder: [":id", ":attachmentIds"]
  },
  addAssetsAttachments: {
    url: "/assets/:id/attachments",
    method: "post",
    placeholder: [":id"]
  },
  saveAssetsAttachmentById: {
    url: "/assets/:id/attachments",
    method: "put",
    placeholder: [":id"]
  },
  putAssetsUsingStatus: {
    url: "/assets/using_status",
    method: "put"
  },

  // 资产帐借出-使用单位 API
  getAssetLendings: {
    url: "/asset_lendings",
    method: "get"
  },
  postAssetLendings: {
    url: "/asset_lendings",
    method: "post"
  },
  putAssetLendingsId: {
    url: "/asset_lendings/:id",
    method: "put",
    placeholder: [":id"]
  },
  getAssetLendingsId: {
    url: "/asset_lendings/:id",
    method: "get",
    placeholder: [":id"]
  },
  putAssetLendingsItemPicking: {
    url: "/asset_lendings/:id/item-picking",
    method: "put",
    placeholder: [":id"]
  },
  putAssetLendingsPicking: {
    url: "/asset_lendings/:id/picking",
    method: "put",
    placeholder: [":id"]
  },
  // /asset_lendings/{id}/picking
  putAssetLendingGiveBack: {
    url: "/asset_lendings/:id/item-giveback",
    method: "put",
    placeholder: [":id"]
  },
  getAssetsIdLends: {
    url: "/assets/:id/lends",
    get: "get",
    placeholder: [":id"]
  },
  putAssetLendingStatus: {
    url: "/asset_lendings/status",
    method: "put"
  }
};
