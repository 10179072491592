import { apis } from "@/apis/index.js";
export default {
  // 获取enum的值
  async getEnumsByIds(ids) {
    let res = await apis.getAllType({
      params: {
        enumTypeId: ids.toString()
      }
    });
    if (res.code != "COMMON.SUCCESS") return {};
    return res.data;
  }
};
