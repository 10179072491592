export default {
  success(state, text) {
    state.alertText = text;
    state.variant = "success";
    state.showDismissibleAlert = 2;
  },
  warning(state, text) {
    state.alertText = text;
    state.variant = "warning";
    state.showDismissibleAlert = 2;
  },
  error(state, text) {
    state.alertText = text;
    state.variant = "danger";
    state.showDismissibleAlert = 2;
  },
  setDismissibleAlert(state, count) {
    state.showDismissibleAlert = count;
  }
};
