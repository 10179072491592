export default () => {
  return {
    dictCodes: [],
    phoneTypes: [],
    careLevels: [
      { label: "1级", value: "1" },
      { label: "2级", value: "2" },
      { label: "3级", value: "3" },
    ],
    washingMethods: [
      { label: "自己洗", value: "1" },
      { label: "他人洗", value: "2" },
    ],
    mealStatus: [
      { label: "状态1", value: "1" },
      { label: "状态2", value: "2" },
    ],
  };
};
