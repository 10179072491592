export default {
  getBpmnProcessInstanceByVaribles: {
    method: "post",
    url: "/engine-rest/process-instance"
  },
  getBpmnProcessActiveInstance: {
    method: "get",
    url: "/engine-rest/process-instance/:id/activity-instances",
    placeholder: [":id"]
  },
  getHistoryTaskList: {
    method: "get",
    url: "/engine-rest/history/process-instance?maxResults=1000"
  },
  getApplyHistoryTaskList: {
    method: "get",
    url: "/bpmns/:uid",
    placeholder: [":uid"]
  },
  claimTaskByUserId: {
    method: "post",
    url: "/engine-rest/task/:id/claim"
  },
  getBpmnDiagram: {
    method: "get",
    url: "/engine-rest/process-definition/:id/xml",
    placeholder: [":id"]
  },
  startProcessInstance: {
    method: "post",
    url: "/engine-rest/process-definition/key/:key/start",
    placeholder: [":key"]
  },
  updateProcessInstanceVaribles: {
    method: "post",
    url: "/engine-rest/process-instance/:id/variables",
    placeholder: [":id"]
  },
  getProcessInstanceVaribles: {
    method: "get",
    url: "/engine-rest/process-instance/:id/variables",
    placeholder: [":id"]
  },
  deleteProcessIntanceById: {
    method: "delete",
    url: "/engine-rest/process-instance/:id",
    placeholder: [":id"]
  },
  getHistoryProcessInstanceVaribles: {
    method: "get",
    url: "/engine-rest/history/variable-instance"
  },
  completeTask: {
    method: "post",
    url: "/engine-rest/task/:taskId/complete",
    placeholder: [":id"]
  },
  getApprovalList: {
    method: "get",
    url: "/engine-rest/history/task"
  },
  // getTaskByPosition: {
  //   method: "get",
  //   url: "/engine-rest/filter/194c44a3-dfeb-11ec-af51-c86000ce3b8e/list"
  // },
  getProcessKeyList: {
    url: "/engine-rest/process-definition?active=true&sortBy=deployTime&sortOrder=desc",
    method: "get"
  },
  removeTaskId: {
    url: "/engine-rest/task/:id",
    method: "delete",
    placeholder: [":id"]
  },
  getBpmnDiagramByName: {
    url: "/diagrams",
    method: "get"
  }
};
