import { apis } from "@/apis/index";
import { SUCCESS_CODE } from "@/apis/notification.js";
// import wx from "wx";
export default {
  async getWeixinAccessToken() {
    let href = location.href.split("#")[0];
    console.log(href);
    let ret = await apis.getWechatAccessToken({ params: { url: btoa(href) } });
    if (ret.code != SUCCESS_CODE) return;
    console.log("retur token", ret.data);
    return ret.data;
  },
  // 注册和获取签名信息，准备wx-js-sdk接口
  ready(jsApiList = []) {
    let resolve;
    let wxPromiseReady = new Promise(function (res) {
      resolve = res;
    });

    (async () => {
      let { signature, noncestr, timestamp } = await this.getWeixinAccessToken();

      wx.error(function (res) {
        // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        console.error("微信签名失败", res);
        resolve(false);
      });

      wx.ready(function () {
        // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
        console.log("wx ready can call api!!");
        resolve(true);
      });

      wx.config({
        debug: false,
        // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: window.process.env.VUE_APP_WECHAT_APPID,
        timestamp,
        nonceStr: noncestr,
        signature,
        jsApiList
      });
    })();

    return wxPromiseReady;
  }
};
