import { pick } from "lodash-es";
export default {
  setHotel({ state }, data) {
    state.hotel = Object.assign(
      {
        hotelName: "",
        hotelId: ""
      },
      pick(data, ["hotelId", "hotelName"])
    );
  }
};
