import Vue from "vue";
// import axios from "axios";
import { Toast } from "vant";
import { ImagePreview } from "vant";
import $store from "@/vuex";
// import router from "router";
// import { dynamicRouterMap, createRouter } from "router";
import { isUndefined, findIndex, merge, cloneDeep } from "lodash-es";
var Filter = require("ldap-filters");
// import thumbnailHandler from "./thumbnailHandler.js";
import { apis } from "../apis";

// 限制输入两位小数
function limitInput(value) {
  let limitValue =
    ("" + value) // 第一步：转成字符串
      .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
  return limitValue;
}

// 函数防抖
let timer = null;
function debounce(fn, delay) {
  // 清除上一次延时器
  clearTimeout(timer);
  // 记录上一次的延时器
  timer = setTimeout(() => {
    fn();
    clearTimeout(timer);
    timer = null;
  }, delay);
}

function hasAuth(name) {
  if (!$store.state.userInfo.grantedAuthority.length) return false;
  let hasReceptAuth = $store.state.userInfo.grantedAuthority.map(item => item.action).indexOf(name) != -1;
  return hasReceptAuth;
}

const getCookie = function (key) {
  var name = key + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const setCookie = function (key, value, expirationDate) {
  var cookie = key + "=" + value + "; " + "expires=" + expirationDate.toUTCString() + "; ";
  document.cookie = cookie;
};

/**
{
  AND: {
    syntax: [
      { attr: "name", value: value, operator: "contains" },
      { attr: "status", value: "ENABLE", operator: "equalTo" }
    ],
    OR: {
      syntax: [
        { attr: "usingStatus", value: "AVAILABLE", operator: "equalTo" },
        { attr: "usingStatus", value: "PICKUP", operator: "equalTo" }
      ]
    }
  }
}
 */
const genLdapStr = function (opts) {
  let keys = Object.keys(opts);
  let logic = keys[0];
  if (!logic) return;
  let paramsStr = "";

  let loop = opt => {
    let ret = [];
    /* if (opt.syntax) {
      ret = ret.concat(
        opt.syntax.map((item) => {
          return Filter.attribute(item.attr).equalTo(item.value);
        })
      );
    } */
    if (opt.syntax) {
      ret = ret.concat(
        opt.syntax.map(item => {
          let attr = item.attr;
          let value = item.value;
          let operator = item.operator || "equalTo";

          return Filter.attribute(attr)[operator](value);
        })
      );
    }

    if (opt.length) {
      ret = ret.concat(opt.map(item => loop(item)));
    }

    if (opt.AND) {
      ret = ret.concat(Filter.AND(loop(opt.AND)));
    }

    if (opt.OR) {
      ret = ret.concat(Filter.OR(loop(opt.OR)));
    }

    if (opt.NOT) {
      ret = ret.concat(Filter.NOT(loop(opt.NOT)));
    }

    return ret;
  };

  let subStrs = loop(opts[logic]);

  paramsStr = Filter[logic](subStrs).toString();

  paramsStr = Filter.parse(paramsStr)
    .simplify()
    .toString();
  return paramsStr;
};

const jumpLogin = function () {
  $store.dispatch("setValidPerformanceTime", "");
  // location.href = "/login?msg=token已过期&redirectUrl=" + window.location.href;
  Vue.prototype.$keycloak.logout();
};

/**
 * {q} params must check before invoke this function;
 * @param {*} context this
 * @param {*} q query params
 */
const updateCurrentRouter = function (context, q = {}) {
  let query = context.$router.history.current.query;
  let path = context.$router.history.current.path;
  let newQuery = JSON.parse(JSON.stringify(query));
  merge(newQuery, q);

  let isSame = function (a = {}, b = {}) {
    return Object.keys(a).every(key => {
      return a[key] === b[key];
    });
  };

  if (!Object.keys(newQuery).length || isSame(newQuery, query)) {
    return;
  }
  context.$router.replace({ path, query: newQuery });
};

/**
 *
 * @param {description: get localstorage or cookie key} keys
 */
const getSession = function (keys) {
  if (isArray(keys)) {
    let ret = {};
    if (!keys.length) return ret;

    keys.map(item => {
      return (ret[item] = sessionStorage.getItem(item));
    });
    return ret;
  }

  if (isString(keys)) {
    return sessionStorage.getItem(keys);
  }
};

/**
 *
 * @param {keys to set} keys
 * @param {values to set} values
 */
const setSession = function (keys, values) {
  if (isArray(keys)) {
    return keys.forEach((item, index) => {
      return sessionStorage.setItem(item, values[index]);
    });
  }

  if (isString(keys)) {
    return sessionStorage.setItem(keys, values);
  }
};

// jwt token expired
const aboutToExpired = function () {
  let { token, expireAt } = {
    token: Vue.prototype.$keycloak.token,
    expireAt: (Vue.prototype.$keycloak.tokenParsed.exp + Vue.prototype.$keycloak.timeSkew) * 1000
  };
  if (!token || !expireAt) return false;

  let remainSeconds = (expireAt - Date.now()) / 1000;
  let maxSeconds = 3 * 60;
  let minSeconds = 0;
  if (remainSeconds < maxSeconds && remainSeconds > minSeconds) return true;
  return false;
};

// 是否过期
const isExpired = function () {
  let retJson = {
    token: Vue.prototype.$keycloak.token,
    expireAt: (Vue.prototype.$keycloak.tokenParsed.exp + Vue.prototype.$keycloak.timeSkew) * 1000
  };

  let { token, expireAt } = retJson;
  if (!token || !expireAt) return true;

  let remainSeconds = expireAt - Date.now();
  if (remainSeconds <= 0) return true;
  return false;
};

// 刷新keycloak token
let isRefreshing = false;
const getKeyCloakToken = async function () {
  let $keycloak = Vue.prototype.$keycloak;
  let retJson = {
    token: $keycloak.token,
    realm: $keycloak.realm,
    expireAt: $keycloak.tokenParsed.exp * 1000
  };
  if (isExpired()) {
    jumpLogin();
    return retJson;
  }
  if (aboutToExpired() && !isRefreshing) {
    isRefreshing = true;

    let resolvePromise;
    let refreshedPromise = new Promise(function (resolve) {
      resolvePromise = resolve;
    });

    $keycloak
      .updateToken(3 * 60)
      .then(refreshed => {
        resolvePromise(refreshed);
      })
      .catch(e => {
        console.trace(e);
        resolvePromise(-1);
        isRefreshing = false;
      });

    let refreshed = await refreshedPromise;

    isRefreshing = false;
    if (refreshed === -1) {
      jumpLogin();
    }

    if (!refreshed) return retJson;
    console.warn(" refresh token ok bypass api!");
    retJson = {
      token: $keycloak.token,
      expireAt: $keycloak.tokenParsed.exp + $keycloak.timeSkew
    };

    // sessionStorage.setItem("token", retJson.token);
    // sessionStorage.setItem("expireAt", retJson.expireAt);

    // 重置
    isRefreshing = false;
  }

  return retJson;
};

const getBase64ByImgUrl = function (imgUrl) {
  return new Promise(function (resolve) {
    var image = new Image();
    image.src = imgUrl;
    image.onload = function () {
      var base64 = __getDataUrl(image);
      resolve(base64);
    };
  });

  function __getDataUrl(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/" + ext, 0.7); // 壓縮質量
    return dataURL;
  }
};

/**
 * new blank and open window
 */
function openNewWindowTap(context, routerName, params) {
  const { href } = context.$router.resolve({
    name: routerName,
    params: params
  });
  window.open(href, "_blank");
}

// 输入13月 => return 1年1个月
// 输入(12月, 'date')=> return 1年
function parseAge(ageInMonth, type) {
  if (type == "date") {
    ageInMonth = Vue.moment().diff(ageInMonth, "month");
  }

  if (!ageInMonth) return "";

  if (ageInMonth < 12) {
    return ageInMonth + "个月";
  }

  let remainYears = Math.floor(ageInMonth / 12);
  let remainMonths = ageInMonth % 12;

  return remainYears + "岁" + (remainMonths > 0 ? remainMonths + "个月" : "");
}

function parseGender(gender) {
  if (gender == MALE) return "男";
  return "女";
}

function getAge(val) {
  let m = Date.now() - new Date(val).getTime();
  m = Math.floor(m / 1000 / 60 / 60 / 24 / 365);
  return m;
}

// slice value
function slice(value, num) {
  if (!value) return "";
  return value.slice(0, num);
}

// parse dateTime to Date
function parseDate(value, format = "YYYY-MM-DD") {
  if (!value || value.toString().length < 9) return value || "";
  return Vue.moment(value).format(format);
}
// parse number to decimal
function parseToDecimal(value) {
  if (isNaN(value)) return 0;
  return value.toFixed(2);
}

let errorHandler = function (err, vm, info) {
  let { message, name, script, line, column, stack } = err;
  console.trace(err);
  // 在vue提供的error对象中，script、line、column目前是空的。但这些信息其实在错误栈信息里可以看到。
  script = !isUndefined(script) ? script : "";
  line = !isUndefined(line) ? line : 0;
  column = !isUndefined(column) ? line : 0;

  // 解析错误栈信息
  let stackStr = stack ? stack.toString() : `\r\n\r\n${new Date()}\n\r${name}\r\n${message}\r\n${stack}`;

  console.log(stackStr);
  let log = localStorage.getItem("log");
  log += stackStr;
  localStorage.setItem("log", log);
};

// 普通提示
function plain(tips) {
  Toast({
    message: tips,
    duration: 1000,
    position: "top",
    forbidClick: true
  });
}

// 成功 提示
function success(tips) {
  Toast({
    message: tips,
    type: "success",
    duration: 1000,
    position: "top",
    forbidClick: true
  });
}
// 错误提示
function error(tips) {
  Toast({
    message: tips,
    icon: "cross",
    position: "top",
    duration: 2000,
    forbidClick: true
  });
}
// 警告
function warning(tips) {
  Toast({
    message: tips,
    type: "fail",
    duration: 2000,
    forbidClick: true
  });
}

function previewImage(options) {
  ImagePreview(options);
}

// 添加动态路由
function addDynamicRouters() {
  // let authorityName = $store.state.userInfo.grantedAuthority.map(
  //   (item) => item.action
  // );
  // // 有权限
  // function isAuthed(dependOn) {
  //   if (!dependOn) return true;
  //   if (isString(dependOn)) {
  //     return authorityName.indexOf(dependOn) != -1;
  //   }
  //   if (isFunction(dependOn)) {
  //     return dependOn(authorityName);
  //   }
  // }
  // let authRouters = dynamicRouterMap.filter((parent) => {
  //   if (parent.children) {
  //     parent.children = parent.children.filter((children) => {
  //       return isAuthed(children.meta.dependOn);
  //     });
  //     if (parent.children.length) return true;
  //     return false;
  //   }
  //   return isAuthed(parent.meta.dependOn);
  // });
  // router.matcher = createRouter().matcher;
  // router.addRoutes(authRouters);
}

function getTypeOf(val) {
  return Object.prototype.toString.call(val);
}

function isObject(val) {
  return getTypeOf(val) === "[object Object]";
}

function isArray(val) {
  return getTypeOf(val) === "[object Array]";
}

function isString(val) {
  return getTypeOf(val) === "[object String]";
}

function isNull(val) {
  return getTypeOf(val) === "[object Null]";
}

function isNumber(val) {
  return getTypeOf(val) === "[object Number]";
}

function isBoolean(val) {
  return getTypeOf(val) === "[object Boolean]";
}

// 是个函数
function isFunction(val) {
  return getTypeOf(val) === "[object Function]";
}

// 覆盖, 只有在target的key是undefined的时候才会覆盖
function mergeIn(source, target) {
  Object.keys(target).forEach(key => {
    if (source[key] !== undefined) return;
    if (isObject(target[key]) || isArray(target[key])) {
      source[key] = JSON.parse(JSON.stringify(target[key]));
      return;
    }
    source[key] = target[key];
  });

  return source;
}

// 剔除 vue observer的拷贝
// 注意不能拷贝function, 自引用对象等等
function pureCopy(source) {
  return JSON.parse(JSON.stringify(source));
}
// 格式化数据
function pureObject(data) {
  let ret = JSON.parse(JSON.stringify(data));
  Object.keys(ret).forEach(key => {
    ret[key] = new ret[key].constructor().valueOf();
  });
  return ret;
}
// 寻找下标
function findIdx(data, query) {
  return findIndex(data, o => {
    let keys = Object.keys(query);
    return keys.every(key => {
      return o[key] == query[key];
    });
  });
}

export let houseStatus = function (val) {
  if (val == "NOT_RENTAL") return "未租";
  if (val == "HAS_RENTAL") return "未租";
  if (val == "HAS_RELEASE") return "已发布";
  if (val == "NOT_RELEASE") return "未发布";
  return "";
};

function sum(num1 = 0, num2 = 0) {
  var baseNum, baseNum1, baseNum2;
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  return (num1 * baseNum + num2 * baseNum) / baseNum;
}
function sub(num1 = 0, num2 = 0) {
  var baseNum, baseNum1, baseNum2;
  var precision; // 精度
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  precision = baseNum1 >= baseNum2 ? baseNum1 : baseNum2;
  return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
}
function multi(num1 = 1, num2 = 1) {
  var baseNum = 0;
  try {
    baseNum += num1.toString().split(".")[1].length;
  } catch (e) { }
  try {
    baseNum += num2.toString().split(".")[1].length;
  } catch (e) { }
  return (Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", ""))) / Math.pow(10, baseNum);
}

// 重写number 4舍五入不准确的问题
let toFixed = function (n, d = 2) {
  if (isNaN(n)) n = 0;

  if (d <= 0) {
    return n;
  } else {
    let radix = 10 ** d;
    let temp = Math.round(n * radix) / radix;
    return temp;
  }
};

function printGrid(obj) {
  let dom = obj.dom;
  let height = obj.height;
  let _this = obj.target;
  let grid = document.getElementById(dom);
  grid.style.width = "1100px";
  grid.style.height = "100%";
  _this.defaultColDef.floatingFilter = false;
  let newColumnDef = cloneDeep(_this.columnDefs);
  newColumnDef.forEach(item => {
    item.cellClass = "cell-class";
  });
  _this.rowClass = "row-class";
  _this.gridApi.setColumnDefs(newColumnDef);
  _this.gridApi.sizeColumnsToFit();
  _this.gridApi.setDomLayout("print");
  let win = window.open("/print.html", "", "left=100,top=100,screenX=100,screenY=100,innerHeight=700,innerWidth=1000,scrollbars,status");
  win.onload = function () {
    let box = win.document.getElementById("box");
    box.innerHTML = grid.outerHTML;
    win.print();
  };
  setTimeout(() => {
    _this.gridApi.setDomLayout(null);
    grid.style.width = "100%";
    grid.style.height = height;
    let newColumnDefs = cloneDeep(_this.columnDefs);
    _this.defaultColDef.floatingFilter = true;
    _this.gridApi.setColumnDefs(newColumnDefs);
    _this.gridApi.sizeColumnsToFit();
    win.close();
  }, 1000);
}

function exportExcel(obj) {
  let _this = obj.target;
  var params = {
    fileName: obj.fileName,
    sheetName: obj.fileName
  };
  _this.gridApi.exportDataAsExcel(params);
}

function thousand(number) {
  number = number + "";
  var re = /\d{1,3}(?=(\d{3})+$)/g;
  var n1 = number.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
    return s1.replace(re, "$&,") + s2;
  });
  return n1;
}

function urlEncode(param, key, encode) {
  if (param == null) return "";
  var paramStr = "";
  var t = typeof param;
  if (t == "string" || t == "number" || t == "boolean") {
    paramStr += "&" + key + "=" + (encode == null || encode ? param : param);
  } else {
    for (var i in param) {
      var k = key == null ? i : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}

// {classroomId:1, from:'pc', readOnly:1 }
function getFormUrlByKey(formKey, options) {
  let processKeys = {
    itemApply: "",
    assetsLending: "",
    assetsPickup: "",
    classroomReserve: ``,
    test: "",
    approval: ``
  };

  let diagrams = localStorage.getItem("SYS_DIAGRAME");
  if (diagrams) {
    diagrams = JSON.parse(diagrams);
  }
  Object.keys(processKeys).forEach(key => {
    let idx = diagrams.findIndex(item => {
      return item.name === key;
    });
    if (idx == -1) return;
    processKeys[key] = diagrams[idx].processId;
  });

  if (formKey == "classroomReserve" && !options.classroomId) return "";
  // formKey = itemApply assetsLending assetsPickup classroomReserve

  let host = window.process.env.VUE_APP_PROCESS_FORM_HOST;

  let query = {
    formKey: formKey,
    processKey: processKeys[formKey] || "",
    ...options
    // token: Vue.prototype.$keycloak.token
  };

  // localStorage.setItem("keyCloakToken", Vue.prototype.$keycloak.token);

  let queryStr = urlEncode(query);
  let url = `${host}/${formKey}?${queryStr}`;

  return url;
}

async function getDictOptions(name) {
  let ret = await apis.getDictsList({
    params: {
      page: false
    }
  });
  if (ret.code != "COMMON.SUCCESS") return;
  return name ? ret.data[name] ? ret.data[name][0] : { dictOptions: [] } : ret.data;
}

async function getBpmnDiagramByName() {
  let ret = await apis.getBpmnDiagramByName({ params: { name: "" } });
  if (ret.code !== "COMMON.SUCCESS") return;
  localStorage.setItem("SYS_DIAGRAME", JSON.stringify(ret.data || []));
}

function getExternalLink(url) {
  let externalLink = url.match(/^(form|bpmn):([/#a-zA-Z1-9_]+)$/);
  let hostName = externalLink && externalLink[1];
  if (hostName == "form") {
    var parseUrl = getFormUrlByKey(externalLink[2]);
  }

  if (hostName == "bpmn") {
    parseUrl = window.process.env.VUE_APP_PROCESS_BPMN_HOST + externalLink[2] + "?withoutheader=1";
  }

  return parseUrl;
}

async function getAuthFileUrl(url) {
  let ret = await apis.getAppendixFilePath({
    params: {
      urls: (Array.isArray(url) ? url : [url]).toString()
    }
  });
  if (ret.code != "COMMON.SUCCESS") return;
  return ret.data;
}

export default {
  // 是否有权限
  hasAuth,

  // 组装自定义查询对象
  genLdapStr,

  // gen base64 and return it by <img> element
  getBase64ByImgUrl,

  // 保存session到缓存
  setSession,
  // 获取缓存
  getSession,
  // // 获取token
  // getToken,
  // token是否即将有效
  aboutToExpired,
  // token是否过期
  isExpired,
  // 刷新token
  getKeyCloakToken,
  // 获取cookie
  getCookie,
  // 设置cookie
  setCookie,

  // open new tap in chrome
  openNewWindowTap,
  // 字符串分割
  slice,

  // 日期格式转换
  parseDate,
  // 转数字,2位小数
  parseToDecimal,
  // 年龄和谐显示
  parseAge,

  // 性别
  parseGender,

  // 更新当前路由
  updateCurrentRouter,

  // 错误追踪
  errorHandler,

  // 压缩为base64
  // compressBase64,
  getTypeOf,
  isArray,
  isObject,
  isNumber,
  isBoolean,
  isFunction,
  isString,
  isNull,
  toFixed,

  // 合并对象数据
  merge: mergeIn,

  plain,
  // 提示:成功
  success,
  // 提示:警告
  warning,
  // 提示: 错误
  error,
  previewImage,

  // 纯净拷贝
  pureCopy,

  // 格式化数据
  pureObject,

  // 添加动态路由
  addDynamicRouters,

  // 寻找下标
  findIndex: findIdx,

  houseStatus,

  sum,

  sub,

  multi,
  limitInput,
  printGrid,
  exportExcel,
  debounce,
  getAuthFileUrl,
  getExternalLink,
  getBpmnDiagramByName,
  getFormUrlByKey,
  getDictOptions,

  //千分制
  thousand,
};
