import bpmn from "./bpmn.js";

const host = process.env.VUE_APP_BPMN_HOST;
Object.keys(bpmn).forEach(key => {
  bpmn[key].url = host + bpmn[key].url;
});

bpmn.getTaskByPosition = {
  method: "get",
  url: host + "/bpmns"
};

export default bpmn;
